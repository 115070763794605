import React from 'react';
import './home.css';

const Home = () => {
    return (
        <div className='home-container'>
                <h2 id='hello'>Hello World! 👋</h2>
                <p id='welcome-message'>
                    I am Valentina, a Full Stack Developer. 🤓
                    <br/>
                    Currently looking for my next adventure! 
                </p>
        </div>
    );
};

export default Home;