import React, { useState } from 'react';
import './education.css';

const Education = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpansion = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  const educationData = [
    {
      school: '4 Geeks Academy',
      degree: 'Full Stack Development Bootcamp',
      date: 'September 2023',
      details: '• Frontend Development: Mastering HTML5, CSS3, and JavaScript for building interactive web applications. Proficient in DOM manipulation, working with arrays, JSON, and utilizing frameworks like React and Bootstrap. Skilled in consuming APIs on the Front-End.\n• Backend Development: Transitioning from JavaScript to Python, with a focus on Back-End application. Building a solid foundation in Python programming, SQL for database management, and object-oriented programming. Utilizing the Flask framework for efficient web application development, including database modeling, querying, and working with APIs. Implementing ORM for seamless data integration.\n• Actively engaging in hands-on projects, collaborating with peers, and following industry best practices, I enhance my problem-solving and teamwork abilities. Also equipped with the ability to build robust and scalable web applications, and ensure a seamless user experience from end to end.'
    },
    {
      school: 'SHMS (Swiss Hotel Management School)',
      degree: 'Bachelor of Arts in Hotel and Events Management',
      date: 'July 2014',
      details: '• GPA: 3.7/4.0\n• Executive Chef of the Student Kitchen\n• Service Lead of the Student Restaurant\n• Decoration Manager during 2nd year banquet and 3rd year World of Hospitality event.'
    },
    // Add more experience entries as needed
  ];

  return (
    <div className='education-container'>
      <h2 id='education'>Education</h2>
      <dl id='education-message'>
        {educationData.map((entry, index) => (
          <div key={index}>
            <dt>
              <span onClick={() => toggleExpansion(index)}>
                {expandedIndex === index ? '✕' : '✛'}
              </span>
              <button className='school-title' onClick={(event) => { event.preventDefault(); toggleExpansion(index); }}>
                  {entry.school}
                </button>
            </dt>
            <dd className='subtitle'>{entry.degree}</dd>
            <dd className='subtitle'>{entry.date}</dd>
            {expandedIndex === index && (
              <dd>
                {entry.details.split('\n').map((line, idx) => (
                  <div key={idx}>{line}</div>
                ))}
              </dd>
            )}
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Education;