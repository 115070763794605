import React from 'react';
import './navbar.css';
import HomeIcon from './navbar-icons/1.svg'
import AboutIcon from './navbar-icons/2.svg'
import ExperienceIcon from './navbar-icons/3.svg'
import SkillsIcon from './navbar-icons/4.svg'
import EducationIcon from './navbar-icons/5.svg'
import ContactIcon from './navbar-icons/6.svg'
import EmptyCircle from './navbar-icons/7.svg'

const Navbar = ({ onComponentChange }) => {
  const handleIconClick = (index) => {
    onComponentChange(index);
};

  const icons = [HomeIcon, AboutIcon, SkillsIcon, ExperienceIcon, EducationIcon, ContactIcon]

  return (
    <div className='navbar'>
{icons.map((Icon, index) => (
    <nav className='icon' key={index} onClick={() => handleIconClick(index)}>
        <img src={EmptyCircle} alt="nav-icon" className="nav-circle"/>
        <img src={Icon} alt="nav-icon" className="nav-icon"/>
    </nav>
))}
    </div>
  );
};

export default Navbar;