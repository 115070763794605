import React from 'react';
import './skills.css';

const Skills = () => {
  return (
    <div className='skills-container'>
      <h2 id='skills'>Skills</h2>
      <ul id='skills-message'>
        <li>Languages: English, Spanish, Italian, French</li>
        <li>Frontend: HTML, CSS, Bootstrap, JavaScript, React</li>
        <li>Backend: Node.js, Python, Flask, Django</li>
        <li>Database: SQL (MySQL, SQLAlchemy), NoSQL (MongoDB)</li>
        <li>REST APIs, Postman</li>
        <li>Git, Github</li>
        <li>AWS, Google Cloud</li>
        <li>Docker, Kubernetes</li>
        <li>Authentication with JWT</li>
        <li>Unit Testing with Jest</li>
        <li>Agile Tools: (Scrum, Kanban) Jira, Trello</li>
        <li>CRM Systems: Salesforce, HubSpot, Zendesk</li>
        <li>Microsoft Office: Excel, Word, Outlook, PowerPoint</li>
        <li>Social Media Software: Facebook, Instagram, LinkedIn, Pinterest, TikTok</li>
      </ul>
    </div>
  );
};

export default Skills;