import React, { useState } from 'react';
import valentinapic from './img/valentina-cv-nobg.png';
import anglearrow from './img/svg/angle-icon.svg';
import DownloadButtonSVG from './img/download-icon/download1.svg';
import DownloadButtonHover from './img/download-icon/download2.svg';
import CVPDF from './CV.pdf';
import './App.css';
import Navbar from './components/navbar/navbar.jsx';
import Home from './components/home/home.jsx';
import About from './components/about/about.jsx';
import Experience from './components/experience/experience.jsx';
import Skills from './components/skills/skills.jsx';
import Education from './components/education/education.jsx';
import Contact from './components/contact/contact.jsx';

function App() {
  const [activeComponent, setActiveComponent] = useState(<Home />);

  const handleComponentChange = (index) => {
    setActiveComponentIndex(index);
    setActiveComponent(components[index]);
};

  const components = [
    <Home />,
    <About />,
    <Skills />,
    <Experience />,
    <Education />,
    <Contact />
  ];
  
  const [activeComponentIndex, setActiveComponentIndex] = useState(0);

  const handleArrowClick = () => {
    const newIndex = (activeComponentIndex + 1) % components.length;
    setActiveComponentIndex(newIndex);
    setActiveComponent(components[newIndex]);
  };

  return (
    <div className="App">
      <div className='website-title'>
        <h1>Valentina Franciosi</h1>
      </div>
      <img src={valentinapic} className='valentina-pic' alt='Valentina Franciosi CV'/>
      <div className='parent-container'>
        <div className="container">
        {activeComponent}
          <Navbar onComponentChange={handleComponentChange} />
          <div className='coming-soon'>
          <h2>Mobile Version Coming Soon!</h2>
          </div>
        </div>
        <img src={anglearrow} alt="" className='arrow-object' onClick={handleArrowClick}/>
      </div>
      <div className='download-component'>
      <a
        href={CVPDF}
        download="Valentina Franciosi CV"
        target="_blank"
        rel="noreferrer"
      >
        <button>
        <img src={DownloadButtonSVG} alt="" className='download-button-default'/>
        <img src={DownloadButtonHover} alt="" className='download-button-hover'/>
        </button> 
      </a>
      </div>
    </div>
  );
}

export default App;
