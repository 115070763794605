import React from 'react';
import './contact.css';
import Github from './social-icons/8.svg';
import GithubHover from './social-icons/8 copy.svg';
import LinkedIn from './social-icons/9.svg';
import LinkedInHover from './social-icons/9 copy.svg';

const Contact = () => {
  return (
    <div className='contact-container'>
      <h2 id='contact'>Contact</h2>
      <ul id='contact-message'>
        <li><a href='mailto:vbfranciosi@gmail.com' className='hyperlink'>Send me an email 📩</a></li>
        <li><a href='tel:+0034665058986' className='hyperlink'>Give me a call ☎️</a></li>
        <li>Socials:
          <br/>
          <a href='https://github.com/valenfrancho' target="_blank" rel="noreferrer">
            <img src={Github} className='social-icons' alt=''/>
            <img src={GithubHover} className='icon-hover GH' alt=''/>
          </a>
          <a href='https://www.linkedin.com/in/valentinabfb/' target="_blank" rel="noreferrer">
            <img src={LinkedIn} className='social-icons' alt=''/>
            <img src={LinkedInHover} className='icon-hover LI' alt=''/>
          </a>
        </li>
        <li>Living in Madrid, ES 🇪🇸</li>

      </ul>
      <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d759.437906966316!2d-3.707500330351478!3d40.41435269821499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42287f3e7843ab%3A0x86b3e34ee96d0746!2sC.%20de%20la%20Lechuga%2C%2028012%20Madrid!5e0!3m2!1sen!2ses!4v1692946753425!5m2!1sen!2ses" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
};

export default Contact;