import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className='about-container'>
      <h2 id='about'>About Me</h2>
      <p id='about-message'>
        I am a Full Stack Developer eager to dive into the creation of dynamic web applications and embracing new technologies throughout the journey. 🚀 
        Seeking a Full Stack Developer role to channel my diverse skill set into designing, developing, and launching innovative web solutions. 
        With my adaptable, detail-oriented, and analytical mindset, I'm dedicated to delivering top-notch software on time. 
        My commitment to continuous learning and professional growth fuels my drive.
        </p>
    </div>
  );
};

export default About;