import React, { useState } from 'react';
import './experience.css';

const Experience = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpansion = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  const experienceData = [
    {
      title: 'Customer Implementation and Operations Manager',
      company: 'Uberall - Madrid, ES (Remote)',
      date: 'April 2022 - June 2023',
      details: '• SaaS onboarding and training of clients and partners, including technical implementation and data management, while ensuring top-notch service delivery at a global scale.\n• Maintaining technical and social relations with A-grade clients, by utilizing analytics to monitor churn and usability, and solving errors.\n• Relationship management of a portfolio of over 50 clients throughout their life cycle with the company, including all Italian-speaking, Spanish-speaking and half of the English-speaking market in EMEA.\n• Assisted engineering team in the customization of store locators using HTML, CSS, and JavaScript.\n• Generated comprehensive data insights on client location information through MySQL queries.\n• Developed and implemented customized APIs to automate client information updates and integration with Google.'
    },
    {
      title: 'Customer Care Supervisor',
      company: 'SiteGround Web Hosting - Madrid, ES (Remote)',
      date: 'October 2020 - March 2022',
      details: '• Lead a 10-agent customer service team, reviewing their daily work, coaching them through written feedback and regular one-on-one meetings.\n• Develop training systems to keep the team up-to-date with all new technologies.\n• Increased customer satisfaction within the Italian market, taking it from 94% to 95.9% in one year.\n• Led my team to become the top-performing in the Customer Care department, through consistent data analysis.\n• Collaborated in the development of a comprehensive technical knowledge base for clients and team members.\n• Personally managed a portfolio of almost 100 customers, B2C and B2B.'
    },
    {
      title: 'Senior Sales Representative',
      company: 'SiteGround Web Hosting - Plovdiv, BG (Remote)',
      date: 'November 2019 - September 2020',
      details: '• Generated daily sales through direct point of contact with Italian customers.\n• Increased direct sales in the Italian market by 17%, with a 92% conversion rate.\n• Sales representative of the month award in June 2020.'
    },
    {
      title: 'Customer Care Representative',
      company: 'SiteGround Web Hosting - Madrid, ES',
      date: 'January 2019 - November 2019',
      details: '• Provide technical support (PHP and WordPress) via chat, phone and email for international clients of a top-tier web hosting company.\n• Consistently top-performer from the moment of training until promotion.\n• Customer service agent of the month award in May and September 2019.'
    },
    // Add more experience entries as needed
  ];

  return (
    <div className='experience-container'>
      <h2 id='experience'>Experience</h2>
      <dl id='experience-message'>
        {experienceData.map((entry, index) => (
          <div key={index}>
            <dt>
              <span onClick={() => toggleExpansion(index)}>
                {expandedIndex === index ? '✕' : '✛'}
              </span>
              <button className='position-title' onClick={(event) => { event.preventDefault(); toggleExpansion(index); }}>
                  {entry.title}
                </button>
            </dt>
            <dd className='subtitle'>{entry.company}</dd>
            <dd className='subtitle'>{entry.date}</dd>
            {expandedIndex === index && (
              <dd>
                {entry.details.split('\n').map((line, idx) => (
                  <div key={idx}>{line}</div>
                ))}
              </dd>
            )}
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Experience;